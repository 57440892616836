import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/insights/sustainable-insights/*" />
    <RAC path="/zh-cn/insights/sustainable-insights/*" />
    <RAC path="/zh-tw/insights/sustainable-insights/*" />
    <RAC path="/pl/insights/sustainable-insights/*" />
    <RAC path="/ru/insights/sustainable-insights/*" />
    <RAC path="/it/insights/sustainable-insights/*" />
    <RAC path="/de/insights/sustainable-insights/*" />
    <RAC path="/es/insights/sustainable-insights/*" />
    <RAC path="/fr/insights/sustainable-insights/*" />
    <RAC path="/ar/insights/sustainable-insights/*" />
    <RAC path="/id/insights/sustainable-insights/*" />
    <RAC path="/pt/insights/sustainable-insights/*" />
    <RAC path="/ko/insights/sustainable-insights/*" />
    <DynamicNotFoundPage default />
  </Router>
)
